<template>
  <div
    class="col-12 d-flex flex-row justify-content-center align-items-center fixed-top"
    id="navbar"
  >
    <div class="col-11 d-flex flex-row justify-content-between pt-3 p-2">
      <div
        @click="toOrder()"
        class="col-md-2 col-lg-1 col-xl-1 d-flex align-items-center coursier-cursor"
      >
        <b-img
          :src="require('../assets/images/logo.png')"
          fluid
          alt="Responsive image"
          class="logo"
        ></b-img>
      </div>
      <b-navbar
        toggleable="lg"
        type="black"
        variant="ixnfo"
        class="display-block-none-1 col-md-10 col-lg-10 col-xl-10"
        style="justify-content: flex-end"
      >
        <div class="d-flex justify-content-end align-items-center">
          <b-navbar-nav
            class="boxrder-red d-flex flex-column align-items-center boite-hover"
          >
            <div
              href="#orderCourse"
              :class="step === 1 ? 'text-color-white' : 'text-color-grey'"
              @click="toOrder()"
              class="col-12 d-flex text-stxyle-no-wrap text-center text-size-12 text-weight-300 coursier-cursor coursier-hover title-hover boxrder-green"
              style="font-weight: normal;"
            >
              Qui sommes-nous
            </div>
          </b-navbar-nav>
          <b-navbar-nav
            class="margin-left-navbar d-flex flex-column align-items-center boite-hover"
          >
            <div
              href="#digitalSolution"
              :class="step === 2 ? 'text-color-white' : 'text-color-grey'"
              @click="toDigital()"
              class="text-styxle-no-wrap text-center text-size-12 text-weight-300 coursier-cursor coursier-hover title-hover"
              style="font-weight: normal;"
            >
              Pourquoi nous choisir
            </div>
          </b-navbar-nav>
          <b-navbar-nav
            class="margin-left-navbar d-flex flex-column align-items-center boite-hover"
          >
            <div
              href="#worksStep"
              :class="step === 3 ? 'text-color-white' : 'text-color-grey'"
              @click="toWorksStep()"
              class="text-stxyle-no-wrap text-center text-size-12 text-weight-300 coursier-cursor coursier-hover title-hover"
              style="font-weight: normal;"
            >
              Comment ça marche
            </div>
          </b-navbar-nav>
          <b-navbar-nav
            class="margin-left-navbar d-flex flex-column align-items-center boite-hover"
          >
            <div
              href="#offreType"
              :class="step === 4 ? 'text-color-white' : 'text-color-grey'"
              @click="toOffreType()"
              class="text-stxyle-no-wrap text-center text-size-12 text-weight-300 coursier-cursor coursier-hover title-hover"
              style="font-weight: normal;"
            >
              Nos offres
            </div>
          </b-navbar-nav>
          <b-navbar-nav
            class="margin-left-navbar  d-flex flex-column align-items-center boite-hover"
          >
            <div
              href="#faq"
              :class="step === 5 ? 'text-color-white' : 'text-color-grey'"
              @click="toFaq()"
              class="text-stxyle-no-wrap text-center text-size-12 text-weight-300 coursier-cursor coursier-hover title-hover border-primary-hover"
              style="font-weight: normal;"
            >
              FAQ
            </div>
          </b-navbar-nav>
          <b-navbar-nav class="d-flex justify-content-end">
            <div class="col-6 d-flex paxdding-8 justify-content-end">
              <b-button
                @click="showDialog"
                size="sm"
                class="coursier-bg-secondary button-commande button-hover coursier-border-secondary border-radius-11"
              >
                <div
                  class="d-flex flex-row align-items-center justify-content-center"
                >
                  <div class="text-nowrap text-size-12 text-weight-500">
                    Contactez un commercial
                  </div>
                </div>
              </b-button>
            </div>
            <div
              class="margin-left col-5 d-flex paxdding-8 justify-content-end"
            >
              <b-button
                @click="toApp"
                size="sm"
                variant="white"
                class="coursier-bg-primary-btn button-commande button-hover coursier-border-primary border-radius-11"
              >
                <div
                  v-if="tk"
                  class="text-nowrap text-size-12 text-weight-500 text-white"
                  style="mxargin:8px 0px 0px 8px"
                >
                  Mon compte
                </div>
                <div
                  v-else
                  class="text-nowrap text-size-12 text-weight-500 text-white"
                  style="mxargin:8px 0px 0px 8px"
                >
                  Lancez une livraison
                </div>
              </b-button>
            </div>
          </b-navbar-nav>
        </div>
      </b-navbar>

      <div
        class="display-block-none-2 width-icon-menu"
        style="height: 40px; z-index: 100 !important"
      >
        <div class="d-flex justify-content-end">
          <b-icon
            @click="dropdown = !dropdown"
            icon="list"
            color="white"
            font-scale="2"
            class="mt-1 bxorder-green cursor-pointer"
          ></b-icon>
        </div>
        <div v-if="dropdown" class="d-flex flex-column card-dropdown">
          <div class="bg-white" style="padding: 18px; border-radius: 5px;">
            <div
              href="#OrderCourse"
              class="cursor-pointer text-nowrap nav"
              :class="step === 1 ? 'coursier-primary' : 'text-black'"
              @click="toOrder()"
            >
              Qui sommes-nous
            </div>
            <div
              href="#DigitalSolution"
              class="cursor-pointer text-nowrap mt-2 nav"
              :class="step === 2 ? 'coursier-primary' : 'text-black'"
              @click="toDigital()"
            >
              Pourquoi nous choisir
            </div>
            <div
              href="#worksStep"
              class="cursor-pointer text-nowrap mt-2 nav"
              :class="step === 3 ? 'coursier-primary' : 'text-black'"
              @click="toWorksStep()"
            >
              Comment ça marche
            </div>
            <div
              href="#offreType"
              class="cursor-pointer text-nowrap mt-2 nav"
              :class="step === 4 ? 'coursier-primary' : 'text-black'"
              @click="toOffreType()"
            >
              Nos offres
            </div>
            <div
              href="#faq"
              class="cursor-pointer mt-2 nav"
              :class="step === 5 ? 'coursier-primary' : 'text-black'"
              @click="toFaq()"
            >
              FAQ
            </div>
            <div>
              <b-button
                @click="showDialog"
                size="sm"
                class="mt-3 coursier-bg-secondary button-commande button-hover coursier-border-secondary border-radius-11"
              >
                <div
                  class="d-flex flex-row align-items-center justify-content-center"
                >
                  <div
                    class="text-nowrap text-size-12 bxorder-green text-weight-500"
                  >
                    Contactez un commercial
                  </div>
                </div>
              </b-button>
            </div>
            <div>
              <b-button
                @click="toApp"
                size="sm"
                class="mt-3 coursier-bg-primary-btn button-commande button-hover coursier-border-primary border-radius-11"
              >
                <div
                  class="d-flex flex-row align-items-center justify-content-center"
                >
                  <div
                    v-if="token"
                    class="text-nowrap text-size-12 bxorder-green text-weight-500 text-white"
                  >
                    Mon compte
                  </div>
                  <div
                    v-else
                    class="text-nowrap text-size-12 bxorder-green text-weight-500 text-white"
                  >
                    Lancez une livraison
                  </div>
                </div>
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['token'],
  name: 'NavBar',
  data () {
    return {
      step: 0,
      dropdown: false,
      tk: ''
    }
  },
  created () {
    window.addEventListener('scroll', this.handleScroll)
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    toOrder () {
      this.step = 1
      this.dropdown = false
      this.$emit('ToOrder')
    },
    toDigital () {
      this.step = 2
      this.dropdown = false
      this.$emit('ToDigital')
    },
    toWorksStep () {
      this.step = 3
      this.dropdown = false
      this.$emit('ToWorksStep')
    },
    toOffreType () {
      this.step = 4
      this.dropdown = false
      this.$emit('ToOffreType')
    },
    toFaq () {
      this.step = 5
      this.dropdown = false
      this.$emit('ToFaq')
    },
    handleScroll () {
      const sections = document.querySelectorAll('div.section')
      // const hrefs = ['orderCourse', 'digitalSolution', 'worksStep', 'offreType', 'faq'];
      sections.forEach((section, index) => {
        if (window.scrollY >= section.offsetTop) {
          this.step = index + 1
          // location.hash = hrefs[index];
        }
      })
      var element = document.getElementById('navbar')
      if (window.scrollY > 30) {
        element.style.backgroundColor = '#252833'
      } else {
        element.style.backgroundColor = '#0B0B0F'
      }
    },
    showDialog () {
      const body = document.body
      body.style.height = '100vh'
      body.style.overflowY = 'hidden'
      location.hash = 'popup1'
      console.log('io ooooo', body)
    },
    toApp () {
      // window.location.href = '/app/'
      if (window.openApp) {
        window.openApp(this.tk, true)
      }
    }
  },
  watch: {
    token () {
      this.tk = this.token
    }
  }
}
</script>
<style scoped>
.boite-hover {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.boite-hover:hover {
  cursor: pointer;
}

.title-hover {
  color: #7a7b7f;
  position: relative;
}

.title-hover::after {
  position: absolute;
  content: '';
  width: 0%;
  height: 2px;
  background-color: #f77f00;
  border-radius: 20px;
  left: 50%;
  bottom: -6px;
  transition: all 0.4s ease-in-out;
}

.boite-hover:hover .title-hover::after {
  width: 100%;
  left: 0;
}

h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.card-dropdown {
  position: absolute;
  top: 50px;
  right: 40px;
}
.coursier-border {
  width: 100%;
  border-radius: 2px;
  border-bottom: 2px solid #f77f00;
  padding-bottom: 5px;
}
/* .border-primary-hover{
  display: flex;
  justify-content: center;
}
.border-primary-hover:hover {
  width: 100%;
  border-radius: 2px;
  border-bottom: 2px solid #F77F00;
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
} */
.button-connect {
  border-radius: 4px;
  height: 45px;
  width: 200px;
}
.button-connect-2 {
  border-radius: 4px;
  height: 45px;
}
.margin-left-navbar {
  margin-left: 16px;
}
.text-size10 {
  font-size: 10pt;
}
.icone-color {
  color: #f77f00;
}
.icon-width {
  width: 50px;
  height: 30px;
}
.display-block-none-1 {
  display: block;
}
.display-block-none-2 {
  display: none;
}
.justify-content-menu {
  justify-content: flex-start;
}
.width-icon-menu {
  width: 0px;
}
.text-style-no-wrap {
  white-space: nowrap;
}
.margin-left-button {
  margin-left: 120px;
}
.coursier-hover:hover {
  color: white;
  transition: all 0.5s ease-in-out;
}
@media (max-width: 575px) {
  .display-block-none-1 {
    display: none;
  }
  .display-block-none-2 {
    display: block;
  }
  .justify-content-menu {
    justify-content: space-between;
  }
  .width-icon-menu {
    width: 100px;
  }
  .card-dropdown {
    z-index: 100;
  }
}
/* @media (min-width: 576px) and (max-width: 767px) {
} */
@media (min-width: 576px) and (max-width: 991px) {
  .justify-content-menu {
    justify-content: space-between;
  }
  .display-block-none-1 {
    display: none;
  }

  .display-block-none-2 {
    display: block;
  }
  .width-icon-menu {
    width: 88px;
  }
  .card-dropdown {
    z-index: 100;
  }
}

@media (min-width: 991px) and (max-width: 1200px) {
  .margin-left-button {
    margin-left: 30px;
  }
}
/* @media (min-width: 600px) and (max-width: 1200px) {
  .text-style-no-wrap {
    white-space: normal;
    text-align: center;
  }
} */
.text-color-white {
  color: #ffffff;
  padding-bottom: 5px;
}
</style>
