<template>
  <div>
    <div class="py-5 d-flex justify-content-center align-items-center">
      <div
        class="py-4 d-flex flex-column justify-content-center align-items-center"
        dxata-aos="zoom-in"
        data-aos-dexlay="200"
        data-aos-duration="200"
      >
        <b-img
          :src="require('../assets/images/logo.png')"
          fluid
          alt="Responsive image"
          class="logo"
        />
        <div class="d-flex my-4 py-3 align-items-center">
          <div class="text-subtitle-2 text-weight-250">Suivez-moi sur :</div>
          <div class="logo-in d-flex justify-content-center cursor-pointer">
            <div
              class="h7 text-weight-bold button-hover"
              @click="toGo(1)"
              style="margin-top: 3px;"
            >
              in
            </div>
          </div>
          <!-- <div class="logo-in d-flex justify-content-center align-items-center cursor-pointer">
            <Button/>
          </div> -->
          <div
            class="logo-in d-flex justify-content-center align-items-center cursor-pointer"
          >
            <b-icon
              icon="instagram"
              @click="toGo(2)"
              width="18px"
              height="18px"
              variant="white"
            ></b-icon>
          </div>
          <div
            class="logo-in d-flex justify-content-center align-items-center cursor-pointer"
          >
            <b-icon
              icon="facebook"
              @click="toGo(3)"
              width="22px"
              height="22px"
              variant="white"
            ></b-icon>
          </div>
          <div
            class="logo-in d-flex justify-content-center align-items-center cursor-pointer"
          >
            <b-icon
              icon="twitter"
              @click="toGo(4)"
              width="21px"
              height="19px"
              variant="white"
            />
          </div>
        </div>
        <div class="text-copyright text-weight-250">
          Copyright - Legacy prevacy
        </div>
        <div
          class="text-copyright text-weight-250 d-flex flex-column flex-sm-row flex-md-row flex-lg-row flex-xl-row mt-4 justify-content-center align-items-center"
        >
          <div @click="toGoCguAndPolitique(0)" style="cursor: pointer;">
            Assistant
          </div>
          <div class="mx-4 d-none d-sm-block d-md-block d-lg-block d-xl-block">
            |
          </div>
          <div class="d-flex mt-3 mt-sm-0 mt-md-0 mt-lg-0 mt-xl-0">
            <div @click="toGoCguAndPolitique(1)" style="cursor: pointer;">
              CGU
            </div>
            <div class="mx-sm-4 mx-md-4 mx-lg-4 mx-xl-4 mx-2">|</div>
            <div @click="toGoCguAndPolitique(2)" style="cursor: pointer;">
              Politique de confidentialité
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import Button from './Button.vue'
export default {
  props: ['token'],
  name: 'App',
  components: {
    // Button
  },
  methods: {
    toGo (value) {
      var liens =
        value === 1
          ? 'https://www.linkedin.com/company/78309306/'
          : value === 2
          ? 'https://www.instagram.com/coursier.ci/'
          : value === 3
          ? 'https://www.facebook.com/Coursierci/'
          : value === 4
          ? 'https://twitter.com/CoursierCi'
          : ''
      window.open(liens, '_blank')
    },
    toGoCguAndPolitique (value) {
      const lists = {
        0: './contact.html',
        1: './cgu.html',
        2: './politique_de_confidentialite.html'
      }
      const lien = lists[value]
      window.open(lien, '_blank')
    }
  }
}
</script>

<style>
.image-logo-footer {
  width: 100px;
}
.text-copyright {
  font-size: 11pt;
  line-height: 26px;
  letter-spacing: 0.7px;
}
.logo-in {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  background-color: #f77f00;
  margin-left: 17px;
}
@media (max-width: 575px) {
}
@media (min-width: 576px) and (max-width: 767px) {
}
@media (min-width: 768px) and (max-width: 991px) {
}
@media (min-width: 992px) and (max-width: 1200px) {
}
@media (min-width: 1201px) {
}
</style>
