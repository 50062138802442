<template>
  <div
    class="margin-top-solution align-items-center padding-40px"
    data-aos="fade-left"
    data-aos-duration="1000"
    style="width: 100%; min-width: 50vw"
  >
    <b-col style="padding: 0px;">
      <b-col xs="12" sm="12" md="12">
        <div class="col-12 d-flex flex-row text-title-solution">
          <!-- Traçabilite & fiabilite - Paiement 100% digitalise - Service de geolocalisation precis -->
        </div>
      </b-col>
      <b-col xs="12" sm="12" md="10" lg="10" class="margin-top-35">
        <div class="d-flex text-size-15 text-weight-bold ">
          <li>Une solution digitale innovante</li>
        </div>
        <div class="d-flex text-subtitle-2 text-weight-250 mt-2">
          Plateforme simple et inscription rapide.<br />
          Paiement 100% digital.<br />
          Suivez vos courses étapes par étapes en temps réel.<br />
          Mode tournée optimisé (jusqu’à X colis à un même coursier).<br />
          Accès à l’historique de vos commandes.
        </div>
      </b-col>
      <b-col xs="12" sm="12" md="10" lg="10" class="margin-top-35">
        <div class="d-flex text-size-15 text-weight-bold ">
          <li>Un excellent rapport qualité prix</li>
        </div>
        <div class="d-flex text-subtitle-2 text-weight-250 mt-2">
          Un service de livraison unique en Côte d’Ivoire.<br />
          Quotation en temps réel.<br />
          Aucun coût additionnel.<br />
        </div>
      </b-col>
      <b-col xs="12" sm="12" md="10" lg="10" class="margin-top-35">
        <div class="d-flex text-size-15 text-weight-bold">
          <li>Une expérience client unique</li>
        </div>
        <div class="d-flex text-subtitle-2 text-weight-250 mt-2">
          Rentrez en contact directement avec votre coursier.<br />
          Service de coursier professionnel.<br />
          Assistance du lundi au vendredi de 9h à 18h.
        </div>
      </b-col>
      <b-col class="margin-top-100">
        <div class="py-2 justify-content-between maxrgin-right-creer">
          <b-button
            @click="toApp"
            size="sm"
            class="coursier-bg-primary-btn button-commande button-hover coursier-border-primary border-radius-11"
          >
            <div
              class="d-flex bxorder-red flex-row align-items-center justify-content-center"
            >
              <!-- <div v-if="tk" class="text-size-12 text-weight-500 text-nowrap d-flex flex-row align-items-center" style="color: white;">
                Mon compte
              </div> -->
              <div
                class="text-size-12 text-weight-500 text-nowrap d-flex flex-row align-items-center"
                style="color: white;"
              >
                Lancez une livraison
              </div>
            </div>
          </b-button>
        </div>
      </b-col>
    </b-col>
  </div>
</template>
<script>
export default {
  props: ['token'],
  data () {
    return {
      tk: ''
    }
  },
  methods: {
    toApp () {
      // window.location.href = '/app/'
      if (window.openApp) {
        window.openApp(this.tk)
      }
    }
  },
  watch: {
    token (tk) {
      this.tk = tk
    }
  }
}
</script>
<style>
.text-title {
  font-size: 38pt;
}
.col-text {
  width: 35%;
}
.col-sous-text {
  width: 85%;
}
.text-subtitle-2 {
  font-size: 13pt;
}
.button-connect {
  width: 230px;
  height: 47px;
  border-radius: 4px;
}
.margin-left-image {
  margin-left: 30px;
}
.margin-top-solution {
  margin-top: 50px;
}
@media (min-width: 570px) {
  .padding-40px {
    padding-left: 50px;
    padding-right: 50px;
  }
}
@media (max-width: 575px) {
  .text-title {
    font-size: 27pt;
  }
  .col-text {
    width: 100%;
  }
  .col-sous-text {
    width: 100%;
  }
  .margin-left-image {
    margin-left: 0px;
  }
  .margin-top-solution {
    margin-top: 30px;
  }
  .margin-left-80px {
    padding-left: 20px;
    padding-right: 20px;
  }
  .margin-left-40px {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .text-title {
    font-size: 29pt;
    line-height: 50px;
  }
  .col-text {
    width: 100%;
  }
  .col-sous-text {
    width: 100%;
  }
  .margin-left-image {
    margin-left: 0px;
  }
  .margin-top-solution {
    margin-top: 30px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .text-title {
    font-size: 29pt;
    line-height: 50px;
  }
  .col-text {
    width: 100%;
  }
  .col-sous-text {
    width: 100%;
  }
  .margin-left-image {
    margin-left: 0px;
  }
  .margin-top-solution {
    margin-top: 30px;
  }
}
@media (min-width: 992px) and (max-width: 1200px) {
}
@media (min-width: 1201px) {
}
</style>
