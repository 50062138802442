<template>
  <b-container
    fluid
    id="app"
    class=" text-white"
    style="padding: 0px !important; background-color: #0b0b0f; overflow-x: hidden;"
  >
    <b-modal
      id="bv-modal-example"
      hide-header
      hide-footer
      body-bg-variant="black"
      header-bg-variant="black"
      class="text-white"
    >
      <h5 class="my-3 text-white">Bientôt disponible !</h5>
    </b-modal>
    <div class="col-12 d-flex flex-row justify-content-center py-2 pxadding-xs">
      <div class="col-xs-12 col-sm-11 col-md-11 col-lg-11 col-xl-11 width-100">
        <NavBar
          @ToOrder="toLink('orderCourse')"
          @ToDigital="toLink('digitalSolution')"
          @ToWorksStep="toLink('worksStep')"
          @ToOffreType="toLink('offreType')"
          @ToFaq="toLink('faq')"
          :token="token"
        />
      </div>
    </div>
    <div
      id="orderCourseAll"
      ref="orderCourse"
      name="orderCourse"
      class="col-12 d-flex flex-row justify-content-center position-absolute section"
    >
      <div id="orderCourse">
        <div
          class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 my-4 padding-top-70 d-flex justify-content-center padding-top-40"
          style="overflow-x: hidden;"
        >
          <OrderCourse :token="token" />
        </div>
      </div>
    </div>

    <div id="elementBlockTransparent" class="block block-transparent"></div>

    <div
      id="KKK"
      class="position-absolute"
      style="background-color: #0b0b0f; overflow-x: hidden;"
    >
      <div
        ref="digitalSolution"
        name="digitalSolution"
        class="col-12 d-flex flex-row align-items-center margin-top-50 section"
      >
        <div class="col-4">
          <hr />
        </div>
        <div class="col-4 text-separator">POURQUOI NOUS CHOISIR ?</div>
        <div class="col-4">
          <hr />
        </div>
      </div>
      <div id="elementDigitalSolution" class="flex-conditionne block">
        <div class="children-block d-flex justify-content-center borderx-red">
          <div
            id="digitalSolutionImage"
            class="width-100 d-flex border-xred justify-content-center"
            style="overflow-x: hidden; opacity: 0"
          >
            <DigitalSolutionImage :token="token" />
          </div>
        </div>
        <div class="children-block bordexr-red">
          <div
            id="digitalSolution"
            class="border-rexd"
            style="overflow-x: hidden;"
          >
            <DigitalSolution :token="token" />
          </div>
        </div>
      </div>
      <div id="elementDifferentModes" class="d-flex flex-column">
        <div ref="differentModes" class="block paddxing-top-70 boxrder-red">
          <div class="col-12 d-flex d-flex my-5 flex-column padxding-80">
            <div class="col-12 d-flex flex-row align-items-center">
              <div class="col-4">
                <hr />
              </div>
              <div class="col-4 text-separator">NOS DIFFÉRENTS MODE</div>
              <div class="col-4">
                <hr />
              </div>
            </div>
            <div
              class="mt-5 d-flex flex-row justify-content-center padding-xs mt-5"
            >
              <div class="col-xs-12 col-sm-12 col-md-11 col-lg-11 col-xl-11">
                <DifferentModes
                  @ToCourseSimple="ChangeEtape"
                  @ToTourneExpress="ChangeEtape"
                  @ToTourneStandard="ChangeEtape"
                  :token="token"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          ref="worksStep"
          id="elementWorksStep"
          name="worksStep"
          class="col-10 d-flex block section bxorder-green"
        >
          <div
            v-if="etape == 1"
            ref="courseSimple"
            class="padding-top-conxtact d-flex flex-row justify-content-center padding-xs"
            style="width: 100%;"
          >
            <div
              class="col-xs-12 col-sm-11 col-md-11 col-lg-11 col-xl-11 d-flex padding-top-30"
            >
              <WorksStepSimple
                class=" margin-top-35 mt-4 mt-sm-5 mt-md-5 mt-lg-5 mt-xl-5"
                :token="token"
              />
            </div>
          </div>
          <div
            v-if="etape == 2"
            ref="tourneExpress"
            class="padding-top-contact bxorder-red d-flex flex-row justify-content-center padding-xs"
            style="width: 100vw;"
          >
            <div
              class="col-xs-12 col-sm-11 col-md-11 col-lg-11 col-xl-11 d-flex padding-top-30"
            >
              <WorksStepExpress
                class=" margin-top-35 mt-0 mt-sm-5 mt-md-5 mt-lg-5 mt-xl-5"
                :token="token"
              />
            </div>
          </div>
          <div
            v-if="etape == 3"
            ref="tourneStandard"
            class="padding-top-contact d-flex flex-row justify-content-center padding-xs"
            style="width: 100vw;"
          >
            <div
              class="col-xs-12 col-sm-11 col-md-11 col-lg-11 col-xl-11 d-flex padding-top-30"
            >
              <WorksStepStandard
                class=" margin-top-35 mt-0 mt-sm-5 mt-md-5 mt-lg-5 mt-xl-5"
                :token="token"
              />
            </div>
          </div>
        </div>
        <div id="elementOffreType" class="block">
          <div
            class="d-flex flex-column padding-top-5 mt-4"
            style="width: 100vw;"
          >
            <div
              ref="offreType"
              id="offreType"
              style="margin-top: 20px"
              class="section"
            />
            <div
              class="padding-80 d-flex col-12 d-flex flex-row align-items-center"
              style="margin-top: 20px"
            >
              <div class="col-4">
                <hr />
              </div>
              <div class="col-4 text-separator">NOS OFFRES</div>
              <div class="col-4">
                <hr />
              </div>
            </div>
            <div
              class="padding-top-5-2 d-flex flex-row justify-content-center padding-xs"
            >
              <div class="col-xs-12 col-sm-11 col-md-11 col-lg-11 col-xl-11">
                <OffreType :token="token" />
              </div>
            </div>
          </div>
        </div>
        <div id="elementOportunity" class="block">
          <div class="d-flex flex-column mt-3" style="width: 100vw;">
            <div
              ref="oportunity"
              class="mt-5 d-flex col-12 d-flex flex-row align-items-center margin-top-50"
            >
              <div class="col-4">
                <hr />
              </div>
              <div class="col-4 text-separator">OPPORTUNITÉS</div>
              <div class="col-4">
                <hr />
              </div>
            </div>
            <div class="mt-4 d-flex flex-row justify-content-center padding-xs">
              <div class="col-xs-12 col-sm-11 col-md-11 col-lg-11 col-xl-11">
                <Oportunity :token="token" />
              </div>
            </div>
          </div>
        </div>
        <div ref="faq" id="elementAskedQuestions" name="faq" class="section">
          <div
            class="padding-top-5 d-flex flex-row justify-content-center padding-xs"
          >
            <div
              class="col-xs-12 col-sm-11 col-md-11 col-lg-11 col-xl-11 margin-top-35"
            >
              <AskedQuestions :token="token" />
            </div>
          </div>
        </div>
        <div id="elementFooter" class="width-100 margin-top-100">
          <div class="mxt-5" style="background-color: #252833">
            <Footer :token="token" />
          </div>
        </div>
      </div>
    </div>
  </b-container>
</template>
<script>
import NavBar from './components/NavBar.vue'
import OrderCourse from './components/OrderCourse.vue'
import DigitalSolution from './components/DigitalSolution.vue'
import DigitalSolutionImage from './components/DigitalSolutionImage.vue'
import WorksStepSimple from './components/WorksStepSimple.vue'
import WorksStepStandard from './components/WorksStepStandard.vue'
import WorksStepExpress from './components/WorksStepExpress.vue'
import OffreType from './components/OffreType.vue'
import Oportunity from './components/Opportunity.vue'
import DifferentModes from './components/DifferentModes.vue'
import AskedQuestions from './components/AskedQuestions.vue'
import Footer from './components/Footer.vue'
export default {
  name: 'App',
  components: {
    NavBar,
    OrderCourse,
    DigitalSolution,
    DigitalSolutionImage,
    WorksStepSimple,
    WorksStepStandard,
    WorksStepExpress,
    OffreType,
    Oportunity,
    DifferentModes,
    AskedQuestions,
    Footer
  },
  data () {
    return {
      etape: 1,
      element: '',
      token: ''
    }
  },
  created () {
    window.addEventListener('scroll', this.scrolleVue)
    window.addEventListener('resize', this.onResize)
    ;[
      'click',
      'contextmenu',
      'dblclick',
      'mousedown',
      'mouseenter',
      'mouseleave',
      'mousemove',
      'mouseout',
      'mouseover',
      'mouseup'
    ].forEach(event => {
      document.body.addEventListener(event, () => {
        this.checkToken()
      })
    })
  },
  destroyed () {
    window.removeEventListener('scroll', this.scrolleVue)
    window.removeEventListener('resize', this.onResize)
  },
  mounted () {
    this.checkToken()
    this.onResize(true)
  },
  methods: {
    toLink (refName) {
      const element = this.$refs[refName]
      location.hash = refName
      element.scrollIntoView()
    },
    ScrollToElement (element) {
      var top = this.$refs[element].offsetTop
      var expandedTop = document.getElementById('elementDifferentModes')
        .offsetTop

      if (element != 'orderCourse' && element != 'digitalSolution') {
        top += expandedTop //+ 10
      }

      window.scrollTo({
        top: top,
        left: 0,
        behavior: 'smooth'
      })
    },
    async ChangeEtape (payload) {
      this.etape = payload.etape
      this.element = payload.element
      await this.$nextTick()
      this.toLink(this.element)
      // this.ScrollToElement(this.element)
    },
    animations (el, direction, y) {
      var width = window.innerWidth / 2
      // console.log('width ::::: ', width)
      var x1 = -width * 0.8 + y
      var x2 = width * 0.8 - y

      if (x1 > 0) {
        x1 = 0
      }

      if (x2 < 0) {
        x2 = 0
      }

      let o = 1 + x1 / 1000
      if (o <= 0.35) {
        o = 0
      }
      document.getElementById('digitalSolutionImage').style.opacity = o
      document.getElementById('digitalSolution').style.opacity = o
      // document.getElementById('KKK').style.opacity = o;
      // document.getElementById('KKK').style.backgroundColor = `rgba(0,0,0,${o})`;
      // console.log({ x1, x2, o }, o <= .1)

      if (direction == 'left') {
        document.getElementById(el).style.marginLeft = x1 + 'px'
      } else {
        document.getElementById(el).style.marginLeft = x2 + 'px'
      }
    },
    scrolleVue () {
      this.checkToken()
      var y = window.scrollY
      var elementOrderCourse = document.getElementById('orderCourse')
      var seuil = 0
      if (elementOrderCourse.offsetHeight > window.innerHeight) {
        seuil = elementOrderCourse.offsetHeight - window.innerHeight + 30
      } else {
        seuil = 120
      }
      // console.log('heightSeuil ==== > ', y, seuil)

      if (y > seuil) {
        document
          .getElementById('orderCourse')
          .setAttribute('class', 'block position-fixed')
        document
          .getElementById('orderCourse')
          .setAttribute('style', 'margin-top: -' + seuil + 'px')
      } else {
        document
          .getElementById('orderCourse')
          .setAttribute('class', 'block position-absolute')
        document
          .getElementById('orderCourse')
          .setAttribute('style', 'margin-top: 0px')
      }
      this.animations('digitalSolutionImage', 'left', y)
      this.animations('digitalSolution', 'right', y)
    },
    onResize () {
      this.scrolleVue()
      var elementOrderCourse = document.getElementById('orderCourse')
      var offsetElementOrderCourse = elementOrderCourse.offsetHeight
      console.log('io', elementOrderCourse.offsetHeight)
      var maxHeight = elementOrderCourse.offsetHeight

      if (maxHeight < 600) {
        offsetElementOrderCourse = elementOrderCourse.offsetHeight
      } else {
        offsetElementOrderCourse = elementOrderCourse.offsetHeight + 150
      }

      var elementBlockTransparent = document.getElementById(
        'elementBlockTransparent'
      )
      elementBlockTransparent.style.height = offsetElementOrderCourse + 'px'
    },
    checkToken () {
      if (
        [undefined, 'undefined', 'null', null, 0, '0', ''].indexOf(
          window.localStorage.coursier_mdg
        ) === -1
      ) {
        this.token = window.localStorage.coursier_mdg
      } else {
        this.token = ''
        this.$forceUpdate()
      }
    }
  }
}
</script>
<style>
@media (max-width: 575px) {
}
@media (min-width: 576px) and (max-width: 767px) {
}
@media (min-width: 768px) and (max-width: 991px) {
}
@media (min-width: 992px) and (max-width: 1200px) {
}
@media (min-width: 1201px) {
}
</style>
