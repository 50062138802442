<template>
  <b-container fluid class="boxrder-green">
    <b-row class="justify-content-center">
      <b-col
        md="12"
        lg="9"
        xl="9"
        class="padding-0 d-flex justify-content-center flex-column"
      >
        <div class="col-12 d-flex justify-content-center">
          <div class="dispxlay-gt-sm d-flex col-display">
            <div class="d-flex-type margin-x">
              <div
                @mouseover="setHovering(true, 1)"
                @mouseout="setHovering(false, 1)"
                @click="toCourseSimple()"
                class="cursor-pointer col-type d-flex column justify-content-center align-items-center"
              >
                <b-img
                  :src="
                    hovering_1
                      ? require('../assets/images/course-blanc.png')
                      : require('../assets/images/course-simple.png')
                  "
                  fluid
                  alt="Responsive image"
                  style="width: 100px;"
                  data-aos="flip-right"
                  data-aos-dexlay="20"
                  data-aos-duration="700"
                  data-aos-offset="130"
                  class="myButton"
                />
              </div>
              <div
                class=" col-type2 d-flex column justify-content-center align-items-center"
              >
                <b-img
                  :src="require('../assets/images/fleche-tiret-1.png')"
                  fluid
                  alt="Responsive image"
                  style="width: 130px;"
                  data-aos="flip-right"
                  data-aos-dexlay="20"
                  data-aos-duration="700"
                  data-aos-offset="100"
                />
              </div>
              <div
                @mouseover="setHovering(true, 2)"
                @mouseout="setHovering(false, 2)"
                @click="toTourneExpress()"
                class="cursor-pointer col-type d-flex column justify-content-center align-items-center"
              >
                <b-img
                  :src="
                    hovering_2
                      ? require('../assets/images/express-blanc.png')
                      : require('../assets/images/tourne-express.png')
                  "
                  fluid
                  alt="Responsive image"
                  style="width: 100px;"
                  data-aos="flip-right"
                  data-aos-dexlay="20"
                  data-aos-duration="700"
                  data-aos-offset="130"
                />
              </div>
              <div
                class=" col-type2 d-flex column justify-content-center align-items-center"
              >
                <b-img
                  :src="require('../assets/images/fleche-tiret-2.png')"
                  fluid
                  alt="Responsive image"
                  style="width: 130px;"
                  data-aos="flip-right"
                  data-aos-dexlay="30"
                  data-aos-duration="700"
                  data-aos-offset="100"
                />
              </div>
              <div
                @mouseover="setHovering(true, 3)"
                @mouseout="setHovering(false, 3)"
                @click="toTourneStandard()"
                class="cursor-pointer col-type d-flex column justify-content-center align-items-center"
              >
                <b-img
                  :src="
                    hovering_3
                      ? require('../assets/images/standard-blanc.png')
                      : require('../assets/images/tourne-standard.png')
                  "
                  fluid
                  alt="Responsive image"
                  style="width: 100px;"
                  data-aos="flip-right"
                  data-aos-dexlay="30"
                  data-aos-duration="700"
                  data-aos-offset="130"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          class="col-12 d-flex flex-row justify-content-boite-3 mt-4 pt-2 text-center bxorder-blue"
        >
          <div
            class="col-4 col-sm-3 col-md-3 col-lg-3 col-xl-3 cursor-pointer text-center bxorder-red"
            data-aos="flip-right"
            data-aos-dexlay="30"
            data-aos-duration="700"
            @mouseover="setHovering(true, 1)"
            @mouseout="setHovering(false, 1)"
          >
            <div
              class="text-title-2 d-flex align-items-center justify-content-center text-wrap text-sm-wrap text-md-nowrap text-lg-nowrap text-xl-nowrap bxorder-red"
              style="height: 60px;"
            >
              Course
            </div>
            <div class="text-subtitle-2 text-weight-light">
              Faites livrer vos envois d’un point A à B.
            </div>
          </div>
          <div
            class="col-4 col-sm-3 col-md-3 col-lg-3 col-xl-3 cursor-pointer text-center bxorder-red"
            data-aos="flip-right"
            data-aos-dexlay="30"
            data-aos-duration="700"
            @mouseover="setHovering(true, 2)"
            @mouseout="setHovering(false, 2)"
          >
            <div
              class="text-title-2 d-flex align-items-center justify-content-center text-wrap text-sm-wrap text-md-nowrap text-lg-nowrap text-xl-nowrap"
              style="height: 60px;"
            >
              Tournée Express
            </div>
            <div class="mxt-4 text-subtitle-2 text-weight-light">
              10 destinations max/livré dans la journée.
            </div>
          </div>
          <div
            class="col-4 col-sm-3 col-md-3 col-lg-3 col-xl-3 cursor-pointer bxorder-red"
            data-aos="flip-right"
            data-aos-dexlay="30"
            data-aos-duration="700"
            @mouseover="setHovering(true, 3)"
            @mouseout="setHovering(false, 3)"
            sxtyle="max-height:100px; min-height:100px;"
          >
            <div
              class="text-title-2 d-flex align-items-center justify-content-center text-wrap text-sm-wrap text-md-nowrap text-lg-nowrap text-xl-nowrap"
              style="height: 60px;"
            >
              Tournée Standard
            </div>
            <div class="mxt-4 text-subtitle-2 text-weight-light">
              Sans limite de destination 24/72H.
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
export default {
  name: 'different_modes',
  props: ['token'],
  data () {
    return {
      step: 0,
      hovering_1: false,
      hovering_2: false,
      hovering_3: false
    }
  },
  methods: {
    toCourseSimple () {
      this.step = 1
      this.$emit('ToCourseSimple', {
        etape: this.step,
        element: 'courseSimple'
      })
    },
    toTourneExpress () {
      this.step = 2
      this.$emit('ToTourneExpress', {
        etape: this.step,
        element: 'tourneExpress'
      })
    },
    toTourneStandard () {
      this.step = 3
      this.$emit('ToTourneStandard', {
        etape: this.step,
        element: 'tourneStandard'
      })
    },
    setHovering (tf, key) {
      if (key === 1 && this.hovering_1 !== tf) {
        this.hovering_1 = tf
      } else if (key === 2 && this.hovering_2 !== tf) {
        this.hovering_2 = tf
      } else if (key === 3 && this.hovering_3 !== tf) {
        this.hovering_3 = tf
      }
    }
  }
}
</script>
<style>
.d-flex-type {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.col-type,
.col-type2 {
  flex: 0.17;
}
.justify-content-boite-3 {
  justify-content: space-between;
}
.margin-x {
  margin: 0px 25px 0px 25px;
}
.col-display {
  flex: 0.98;
}
.text-center2 {
  text-align: right;
}
@media (max-width: 575px) {
  .justify-content-boite-3 {
    justify-content: center;
  }
  .col-type2 {
    flex: 0.17;
    padding: 5px;
  }
  .col-type {
    flex: 0.23;
  }
  .margin-x,
  .margin-left {
    margin: 0px !important;
  }
  .padding-0 {
    padding: 0px !important;
  }
  .col-display {
    flex: 0.88;
  }
  .text-center2 {
    text-align: center;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .justify-content-boite-3 {
    justify-content: space-between;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
}
@media (min-width: 992px) and (max-width: 1200px) {
  .margin-left {
    margin-left: 60px;
  }
}
@media (min-width: 1201px) {
}
</style>
