<template>
  <div
    class="col-image d-flex flex-column justify-content-center align-items-center margin-left-image boxrder-red"
    datax-aos="fade-right"
    data-aos-duration="100"
    data-aos-offset="10"
  >
    <div v-for="(image, index) in listPictures" :key="index" :style="{ display: slideIndex == index ? 'block' : 'none' }">
      <div class="col-12 d-flex justify-content-center">
        <img :src="image" class="width-image-mise contain"/>
      </div>
    </div>
    <div class="col-12 d-flex bordxer-red justify-content-center mt-4">
     <div class="col-2 d-flex justify-content-between bxorder-green">
      <div style="text-align:center; padding: 20px 0px;">
        <span v-for="(image, index) in listPictures" :key="index" class="dot" :class="{ 'active': slideIndex == index }" @click="currentSlide(index)"></span>
      </div>
     </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['token'],
  data () {
    return {
      slideIndexTmp: 1,
      listPictures: [
        require('../assets/images/phone.png'),
        require('../assets/images/phone1.png'),
        require('../assets/images/phone2.png')
      ],
      slideIndex: 1,
    }
  },
  mounted () {
    this.slideIndex = 0 
  },
  methods: {
    load () {
      this.slides = document.getElementsByClassName("mySlides");
      this.dots = document.getElementsByClassName("dot");
      this.slideIndex = 1;
      this.showSlides(this.slideIndex, true);
    },
    currentSlide(n) {
      this.slideIndex = n
    }
  },
}
</script>

<style>
.margin-left-image {
  margin-left: 30px;
}
.col-image {
  width: 60%;
}
.width-image-mise {
  width: 430px;
}

@media (max-width: 575px) {
  .margin-left-image {
    margin-left: 0px;
  }
  .col-image {
    margin-top: 20px;
    width: 100%;
  }
  .width-image-mise {
    width: 60%;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .margin-left-image {
    margin-left: 0px;
  }
  .col-image {
    margin-top: 30px;
    width: 100%;
  }
  .width-image-mise {
    width: 60%;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .margin-left-image {
    margin-left: 0px;
  }
  .col-image {
    width: 100%;
  }
  .width-image-mise {
    width: 80%;
  }
}
@media (min-width: 992px) and (max-width: 1200px) {
  .col-image {
    width: 80%;
  }
}
@media (min-width: 1201px) {
}
</style>
