import Vue from 'vue'
import App from './App.vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import VueRouter from 'vue-router'

import './assets/css/app.css'

import VueAnimateOnScroll from 'vue-animate-onscroll'

import AOS from 'aos'
import 'aos/dist/aos.css'
// const host = 'http://192.168.88.28:4001'
// const host = 'https://coursier.ci'

// import HelloWorld from './components/HelloWorld.vue'

Vue.use(VueRouter)

Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
Vue.use(AOS.init())
Vue.use(VueAnimateOnScroll)

// const routes = [
//   { path: '/', component: HelloWorld() },
// ]

// // 3. Create the router instance and pass the `routes` option
// // You can pass in additional options here, but let's
// // keep it simple for now.
// const router = new VueRouter({
//   routes // short for `routes: routes`
// })

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  beforeMount () {
    // window.openApp = () => this.$bvModal.show('bv-modal-example')
    window.openApp = function (tk, isAuth) {
      const link = tk ? '' : isAuth ? 'login' : ''
      console.log({ link })
      const isAndroid =
        navigator.userAgent.toLowerCase().indexOf('android') > -1 ||
        /(android)/i.test(navigator.userAgent)
      const isIos =
        navigator.userAgent.toLowerCase().indexOf('iphone') > -1 ||
        /iPad|iPhone|iPod/.test(navigator.userAgent)

      setTimeout(function () {
        if (!isAndroid && !isIos) {
          window.location = `./app/${link}`
        }
      }, 500)
      window.location = `coursier://${link}`
      setTimeout(function () {
        if (isAndroid) {
          window.location =
            'http://play.google.com/store/apps/details?id=ci.coursier.prod'
        } else if (isIos) {
          window.location = 'https://apps.apple.com/app/coursier/id6443798491'
        }
      }, 1500)
    }
  }
}).$mount('#app')
