<template>
  <div class="padding-top-70">
    <b-row class="mt-4 align-itxems-end justify-content-center">
      <b-col
        xs="12"
        sm="11"
        md="9"
        lg="9"
        xl="8"
        class="d-flex justify-content-center"
      >
        <div
          class="text-title-3 text-center"
          data-aos="zoom-in"
          data-aos-duration="700"
          data-aos-delay="200"
        >
          Frequently asked questions
        </div>
      </b-col>
      <b-col
        xs="12"
        sm="11"
        md="9"
        lg="9"
        xl="8"
        class="d-flex justify-content-center margin-top-50"
      >
        <div
          class=" text-center"
          data-aos="zoom-in"
          data-aos-duration="700"
          data-aos-delay="200"
          style="font-size: 18px"
        >
          Comment localiser votre livreur ? Nous utilisons la technologie de "
          Géolocalisation " afin de localiser au mieux votre livreur
        </div>
      </b-col>
      <b-col xs="12" sm="11" md="9" lg="9" xl="8" class="margin-top-100">
        <div v-for="(items, index) in listsAskedQuestions" :key="index">
          <div
            data-aos="fade-up"
            data-aos-anchor-placement="center-bottom"
            :data-aos-delay="200 * index"
            data-aos-duration="700"
            style="cursor: pointer"
            @click="items.check = !items.check"
          >
            <div
              :class="items.check ? null : 'collapsed'"
              :aria-expanded="items.check ? 'true' : 'false'"
              class=" text-subtitle-3 text-bold d-flex justify-content-between"
            >
              <div class="col-11 d-flex">{{ items.title }}</div>
              <b-img
                v-if="!items.check"
                :src="require('../assets/images/plus.png')"
                width="13px"
                height="12px"
                class="coursier-primary text-weight-bold"
              />
              <b-img
                v-else
                :src="require('../assets/images/moins.png')"
                width="15px"
                height="7px"
                class="coursier-primary text-weight-bold"
              />
            </div>
            <b-collapse v-model="items.check">
              <div class="col-10 d-flex padding-collapse">
                <div
                  v-html="items.description"
                  class="text-subtitle-2 text-weight-250"
                ></div>
              </div>
            </b-collapse>
            <div
              class="padding-top-10  d-flex col-12 d-flex flex-row align-items-center"
            >
              <div class="col-12">
                <hr />
              </div>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
export default {
  props: ['token'],
  data () {
    return {
      tk: '',
      listsAskedQuestions: [
        {
          title: 'Quels sont les formats acceptés pour les livraisons? .',
          description:
            'Nous vous proposons trois formats de livraisons, les plis, les colis et les sacs.<br/> Pour les envois de plis: toutes dimensions.<br/>Pour les envois de colis: 40cm par 40cm par 40cm max<br/>Pour les envois de sacs: 40cm par 40cm par 40cm max',
          check: true
        },
        {
          title: 'Quels sont les différents produits que livre Coursier:',
          description:
            'Livraison de plis <br/> Livraison de colis <br/> Livraison de sacs',
          check: false
        },
        {
          title:
            'Quels sont les différents modes de livraison que propose Coursier: ',
          description: 'Course <br/> Tournée <br/> Tournée Express',
          check: false
        },
        {
          title: 'Quels sont les moyens de paiements acceptés?',
          description:
            'Nous acceptons les paiements par carte de crédit et mobile money (Orange Money, Mtn Momo, Visa, MasterCard…). <br/><br/> Si vous êtes un pro, n’hésitez pas à contacter notre équipe commerciale pour discuter d’une méthode de paiement adapté à vos besoins.',
          check: false
        },
        {
          title: 'Comment demander une facture?',
          description:
            'Coursier.ci vous permet d’accéder à l’historique de vos paiements ainsi qu’au récapitulatif de toutes vos livraisons via l’application. Vos factures sont générées automatiquement à la fin de chaque livraison sur votre espace client et y sont téléchargeables.',
          check: false
        },
        {
          title: 'Que faire si ma commande n’est pas arrivée en bon état?',
          description:
            'Si votre commande n’est pas livré en bon état ou qu’elle est incomplète, veuillez contacter directement le service client au +225 272 43 522 01 .<br/><br/> Nos équipes sont joignables du lundi au vendredi de 9h à 18h.',
          check: false
        },
        {
          title: 'Je n’arrive pas à contacter mon coursier comment faire ?',
          description:
            'Munissez-vous de votre numéro de commande et contactez directement le service client au +225 272 43 522 01 . Nos équipes sont joignables du lundi au vendredi de 9h à 18h. ',
          check: false
        }
      ]
    }
  },
  watch: {
    token (tk) {
      this.tk = tk
    }
  }
}
</script>

<style scoped>
.padding-top-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}
</style>
