<template>
  <div>
    <b-row
      xs="12"
      sm="10"
      md="10"
      lg="10"
      xl="10"
      class="d-flex justify-content-center"
    >
      <b-col
        xs="12"
        sm="11"
        md="6"
        lg="4"
        xl="4"
        class="d-flex flex-row align-items-stretch padding-14 boxrder-red"
      >
        <div
          class="col-12 d-flex flex-row flex-wrap align-items-start mb-3 mt-3"
          data-aos="flip-right"
          data-aos-duration="1000"
          data-aos-offset="250"
          data-aos-delay="0"
        >
          <b-col
            md="12"
            class="col-12 d-flex justify-content-center borsder-red"
            style="height: 300px"
          >
            <b-img
              :src="require('../assets/images/moto.png')"
              style="width: 100%;"
              fluid
              class="border-radius-15"
            />
          </b-col>
          <div class="mt-5">
            <div cols="12" class="text-title-2">Devenir coursier</div>
          </div>
          <b-col sm="11" md="10" style="margin-top: 20px;">
            <div class="text-subtitle-2 text-weight-250">
              Commencez votre première course avec Coursier.ci et gagnez des
              revenus attractifs en toute simplicité et flexibilité.
            </div>
          </b-col>
          <div class="col-xs-12 col-sxm-12 col-mdx-7 d-flex mt-4 width-resp">
            <b-button
              size="sm"
              class="d-flex max-width cxol-12 mt-4 justify-content-center align-items-center coursier-bg-primary-btn button-commande button-hover coursier-border-primary border-radius-11"
            >
              <div
                class="d-flex bxorder-red flex-row align-items-center justify-content-center"
              >
                <div
                  class="text-nowrap text-size-12 bxorder-green text-weight-500"
                >
                  Rejoignez-nous
                </div>
              </div>
            </b-button>
          </div>
        </div>
      </b-col>
      <b-col
        xs="12"
        sm="11"
        md="6"
        lg="4"
        xl="4"
        class="d-flex flex-row align-items-stretch padding-14 boxrder-red"
      >
        <div
          class="col-12 d-flex flex-row flex-wrap align-items-start mb-3 mt-3 borxder-red"
          data-aos="flip-left"
          data-aos-duration="1000"
          data-aos-offset="250"
          data-aos-delay="200"
        >
          <b-col
            md="12"
            class="col-12 d-flex justify-content-center bordser-red"
            style="height: 300px"
          >
            <b-img
              :src="require('../assets/images/bicyclette.png')"
              style="width: 100%;"
              fluid
              class=" border-radius-15"
            />
          </b-col>
          <div class="mt-5">
            <div cols="12" class="text-title-2">
              Je suis un professionnel/Une entreprise
            </div>
          </div>
          <b-col sm="11" md="10" style="margin-top: 20px;">
            <div class="text-subtitle-2 text-weight-250">
              Vous êtes un professionnel ou une entreprise et souhaitez avoir
              une offre sur-mesure/personnalisé ?
            </div>
          </b-col>
          <div
            class="col-xxs-12 coxl-sm-12 coxl-md-8 cxol-lg-8 d-flex mt-4 width-resp"
          >
            <b-button
              @click="showDialog"
              size="sm"
              class="d-flex max-width coxl-12 mt-4 justify-content-center align-items-center coursier-bg-primary-btn button-commande button-hover coursier-border-primary border-radius-11"
            >
              <div
                class="d-flex bxorder-red flex-row align-items-center justify-content-center"
              >
                <div
                  class="text-nowrap text-size-12 bxorder-green text-weight-500"
                >
                  Contactez un commercial
                </div>
              </div>
            </b-button>
          </div>
        </div>
      </b-col>
      <b-col
        xs="12"
        sm="11"
        md="6"
        lg="4"
        xl="4"
        class="d-flex flex-row align-items-stretch padding-14 borxder-red justify-content-start"
      >
        <div
          class="col-12 d-flex flex-row flex-wrap align-items-start mb-3 mt-3 borderx-red"
          data-aos="flip-right"
          data-aos-duration="1000"
          data-aos-offset="250"
          data-aos-delay="400"
          style="width: 100%"
        >
          <b-col
            md="12"
            class="col-12 d-flex justify-content-center borxder-red"
            style="height: 300px"
          >
            <b-img
              :src="require('../assets/images/charger-camion.png')"
              style="width: 100%;"
              fluid
              class="wixdth-image border-radius-15"
            />
          </b-col>
          <div class="mt-5 border-rexd">
            <div cols="12" class="text-title-2">Rejoindre l'équipe</div>
          </div>
          <b-col sm="11" md="10" style="margin-top: 20px;">
            <div class="text-subtitle-2 text-weight-250">
              Coursier.ci est toujours à la recherche de nouveaux talents pour
              rejoindre l’aventure, n’hésitez pas à postuler !
            </div>
          </b-col>
          <div
            class="col-xxs-12 col-sxm-12 col-mxd-7 d-flex width-resp mt-4 borderx-red align-self-end"
          >
            <b-button
              size="sm"
              class="d-flex max-width cxol-12 mt-4 justify-content-center align-items-center coursier-bg-primary-btn button-commande button-hover coursier-border-primary border-radius-11"
            >
              <div
                class="d-flex bxorder-red flex-row align-items-center justify-content-center"
              >
                <div
                  class="text-nowrap text-size-12 bxorder-green text-weight-500"
                >
                  Je postule
                </div>
              </div>
            </b-button>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  props: ['token'],
  methods: {
    showDialog () {
      const body = window.document.body
      body.style.height = '100vh'
      body.style.overflowY = 'hidden'
      location.hash = 'popup1'
    }
  }
}
</script>
<style scoped>
.text-size-12 {
  font-size: 13px;
}
.linear-gradient {
  background: linear-gradient(black, #434c5c, #434c5c);
}
.border-black {
  border: thin solid black;
}
.width-image {
  height: 255px;
}
.width-image img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.margin-top-opportunity {
  margin-top: 145px;
}

@media (max-width: 575px) {
  .margin-top-opportunity {
    margin-top: 20px;
  }
  .mt {
    margin-top: 40px;
  }
}
@media (max-width: 767px) {
  .width-resp {
    width: 65%;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .margin-top-opportunity {
    margin-top: 40px;
  }
  .mt {
    margin-top: 10px;
  }
}
@media (min-width: 768px) {
  .width-resp {
    width: 75%;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .mt {
    margin-top: 10px;
  }
}
@media (min-width: 992px) and (max-width: 1200px) {
  .mt {
    margin-top: 20px;
  }
}
@media (min-width: 1201px) {
  .mt {
    margin-top: 20px;
  }
}
</style>
