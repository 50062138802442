var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-col',{attrs:{"md":"12"}},[_c('b-row',{staticClass:"mt-3 align-itxems-end justify-content-between",attrs:{"styxle":"padding: 0;"}},[_c('b-col',{attrs:{"md":"5"}},[_c('div',{staticClass:"text-title",attrs:{"data-aos":"zoom-in-right","data-aos-dexlay":"200","data-aos-duration":"700"}},[_vm._v(" Tournée Express ")]),_c('b-col',{staticStyle:{"margin-top":"20px"},attrs:{"data-aos":"zoom-in-right","data-aos-dexlay":"200","data-aos-duration":"700"}},[_c('div',{staticClass:"text-marche mt-4"},[_vm._v("COMMENT ÇA MARCHE ?")])]),_c('b-col',{staticClass:"d-fxlex justifyx-content-center padxding-top-200"},[_c('div',{staticClass:"padding-top-50 mt-4"},_vm._l((_vm.listsEtapes),function(items,index){return _c('div',{key:index},[_c('div',{attrs:{"data-aos":"fade-up","data-aos-anchor-placement":"center-bottom","data-aos-delay":200 * index,"data-aos-duration":"700"},on:{"click":function($event){return _vm.changeValueCheck(index)}}},[_c('div',{staticClass:"d-flex justify-content-between align-items-center cursor-pointer",class:!items.check ? 'collapsed' : null,attrs:{"aria-expanded":items.check ? 'true' : 'false'}},[_c('div',{staticClass:"col-11 d-flex text-size-13",class:{
                    'coursier-primary': items.check,
                    'text-white': !items.check
                  }},[_vm._v(" "+_vm._s(items.title)+" ")]),_c('b-icon',{class:!items.check ? 'flech' : 'coursier-primary',attrs:{"icon":!items.check ? 'arrow-down-short' : 'arrow-right-short',"width":"35px","height":"35px"}})],1),_c('b-collapse',{model:{value:(items.check),callback:function ($$v) {_vm.$set(items, "check", $$v)},expression:"items.check"}},[_c('div',{staticClass:"padding-collapse"},[_c('div',{staticClass:"text-subtitle-2 text-weight-250"},[_vm._v(" "+_vm._s(items.description)+" ")])])]),_c('div',{staticClass:"d-flex col-12 d-flex flex-row align-items-center"},[_c('div',{staticClass:"col-12"},[(!items.check)?_c('hr'):_c('div',{staticClass:"col-12 test margin-collapse",staticStyle:{"margin-bottom":"20px"}})])])],1)])}),0)]),_c('div',{staticClass:"d-flex flex-row",staticStyle:{"margin-top":"40px"}},[_c('div',{staticClass:"d-flex flex-row text-white coursier-border-primary p-1 card-btn-group border-radius-11",attrs:{"data-aos":"zoom-in","data-aos-duration":"700","data-aos-delay":"600","data-aos-once":"false"}},[_c('b-col',{staticClass:"d-flex flex-row align-items-center justify-content-center cursor-pointer border-radius-11",class:_vm.checkDesktopOrPhone
                ? 'coursier-border-primary coursier-bg-primary-btn'
                : '',on:{"click":function($event){return _vm.onCheckDesktopOrPhone(true, 'click')}}},[_c('b-img',{staticClass:"color-tv width-tv",attrs:{"src":require('../assets/images/icon-tele.png'),"fluxid":""}})],1),_c('b-col',{staticClass:"d-flex flex-row align-items-center justify-content-center cursor-pointer border-radius-11",class:!_vm.checkDesktopOrPhone
                ? 'coursier-border-primary coursier-bg-primary-btn'
                : '',on:{"click":function($event){return _vm.onCheckDesktopOrPhone(false, 'click')}}},[_c('b-img',{staticClass:"color-tv width-phone",attrs:{"src":require('../assets/images/icon-phone.png'),"fluxid":""}})],1)],1)])],1),_c('b-col',{staticClass:"d-flex flex-column justify-content-center dicrection-end-center borxder-green padding-top-image",attrs:{"xs":"12","sm":"12","md":"6"}},[_c('div',{staticClass:" slideshow-container col-12 image-sm",staticStyle:{"height":"80%","min-height":"300px"}},[_c('div',{staticClass:"d-flex flex-row align-items-center justify-content-center",staticStyle:{"height":"100%"}},[_c('img',{staticClass:"width-image justify-content-center d-flex",attrs:{"src":_vm.checkDesktopOrPhone
                ? require('../assets/images/Calque.png')
                : require('../assets/images/calque-phone.png')}}),_vm._l((_vm.tabCheckImage),function(image,index){return _c('div',{key:index,staticClass:"mySlides position-absolute"},[_c('img',{staticClass:"width-image",style:({
                height: !_vm.checkDesktopOrPhone ? '645px' : '',
                margin: !_vm.checkDesktopOrPhone ? '-30px 0px 0px 0px ' : ''
              }),attrs:{"src":image}})])})],2),_c('div',{staticClass:"prev",on:{"click":function($event){return _vm.plusSlides(-1)}}},[_c('span',{staticClass:"chevron left"})]),_c('div',{staticClass:"next",on:{"click":function($event){return _vm.plusSlides(1)}}},[_c('span',{staticClass:"chevron right"})])]),_c('div',{staticStyle:{"text-align":"center","padding":"20px 0px"}},_vm._l((_vm.tabCheckImage),function(image,index){return _c('span',{key:index,staticClass:"dot",class:{ active: _vm.slideIndex == index + 1 },on:{"click":function($event){return _vm.currentSlide(index + 1)}}})}),0)])],1),_c('b-col',{staticClass:"d-flex flex-row marging-top-50 justify-content-center",attrs:{"md":"12"}},[_c('div',{staticClass:"py-2 justify-content-between maxrgin-right-creer",attrs:{"data-aos":"zoom-in","data-aos-duration":"400"}},[_c('b-button',{staticClass:"maxx-width coursier-bg-primary-btn button-commande button-hover coursier-border-primary border-radius-11",attrs:{"size":"sm"},on:{"click":_vm.toApp}},[_c('div',{staticClass:"d-flex bxorder-red flex-row align-items-center justify-content-center"},[_c('div',{staticClass:"text-nowrap text-size-12 bxorder-green text-weight-500"},[_vm._v(" Lancez une livraison ")])])])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }