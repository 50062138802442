<template>
  <div sxtyle="padding-top: 5%">
    <b-row
      xs="12"
      sm="12"
      md="10"
      lg="10"
      xl="10"
      class="d-flex bxorder-green justify-content-center"
      style="height: 20%;"
    >
      <b-col xs="12" sm="11" md="6" lg="5" xl="5" class="padding-14">
        <div class="shaxdow px-4 mb-3 rounded-10 coursier-border-primary">
          <div
            class="padding-top-30 mt-3"
            data-aos="zoom-in"
            data-aos-duration="700"
          >
            <div cols="12" class="text-size-32 text-center">Offre pro</div>
          </div>
          <div
            class="d-flex flex-column align-items-center"
            style="margin: 78px 0px 72px 0px;"
          >
            <div class="col-xs-11 col-sm-11 col-md-10">
              <div
                class="d-flex align-items-center mxt-4"
                data-aos="fade-up"
                data-aos-once="false"
                data-aos-offset="180"
                datax-aos-duration="70"
                data-aos-delay="100"
              >
                <b-icon
                  icon="check-circle"
                  width="23px"
                  height="23px"
                  class="coursier-white"
                ></b-icon>
                <div style="margin-left: 13px;">5% de réduction</div>
              </div>
              <div
                class="d-flex align-items-center mt-4"
                data-aos="fade-up"
                data-aos-once="false"
                data-aos-offset="180"
                data-aos-delay="200"
              >
                <b-icon
                  icon="check-circle"
                  width="23px"
                  height="23px"
                  class="coursier-white"
                ></b-icon>
                <div style="margin-left: 13px;">
                  Suivi commercial & Assistance
                </div>
              </div>
              <div
                class="d-flex align-items-center mt-4"
                data-aos="fade-up"
                data-aos-once="false"
                data-aos-offset="180"
                data-aos-delay="300"
              >
                <b-icon
                  icon="check-circle"
                  width="23px"
                  height="23px"
                  class="coursier-white"
                ></b-icon>
                <div style="margin-left: 13px;">
                  Paiment des courses à la fin du mois
                </div>
              </div>
              <!-- <div class="d-flex align-items-center mt-4" data-aos="fade-up" data-aos-once="false" data-aos-offset="180"  data-aos-delay="400">
                <b-icon icon="check-circle" width="23px" height="23px" class="coursier-white"></b-icon>
                <div style="margin-left: 13px;">Imprimante à étiquettes payante</div>
              </div> -->
              <div
                class="coxl-12 d-flex mt-5 max-width"
                data-aos="fade-up"
                data-aos-once="false"
                data-aos-offset="0"
                data-aos-delay="400"
              >
                <b-button
                  @click="showDialog"
                  size="sm"
                  class="max-width d-flex coxl-12 mt-4 justify-content-center align-items-center coursier-bg-primary-btn button-commande button-hover coursier-border-primary border-radius-11"
                >
                  <div
                    class="d-flex bxorder-red flex-row align-items-center justify-content-center"
                  >
                    <div
                      class="text-nowrap text-size-12 bxorder-green text-weight-500"
                    >
                      Contactez un commercial
                    </div>
                  </div>
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </b-col>
      <b-col xs="12" sm="11" md="6" lg="5" xl="5" class="padding-14">
        <div class="px-4 mb-3 rounded-10 border-black linear-gradient">
          <div
            class="padding-top-30 mt-3"
            data-aos="zoom-in"
            data-aos-duration="700"
          >
            <div cols="12" class="text-size-32 text-center">
              Offre pro <span class="coursier-secondary">+</span>
            </div>
          </div>
          <div
            class="d-flex flex-column align-items-center"
            style="margin: 78px 0px 72px 0px;"
          >
            <div class="col-xs-11 col-sm-11 col-md-10">
              <div
                class="d-flex align-items-center mxt-4"
                data-aos="fade-up"
                data-aos-once="false"
                data-aos-offset="180"
                datax-aos-duration="70"
                data-aos-delay="100"
              >
                <b-icon
                  icon="check-circle"
                  width="23px"
                  height="23px"
                  class="coursier-white"
                ></b-icon>
                <div style="margin-left: 13px;">10% de réduction</div>
              </div>
              <div
                class="d-flex align-items-center mt-4"
                data-aos="fade-up"
                data-aos-once="false"
                data-aos-offset="180"
                datax-aos-duration="70"
                data-aos-delay="200"
              >
                <b-icon
                  icon="check-circle"
                  width="23px"
                  height="23px"
                  class="coursier-white"
                ></b-icon>
                <div style="margin-left: 13px;">
                  Suivi commercial & Assistance
                </div>
              </div>
              <div
                class="d-flex align-items-center mt-4"
                data-aos="fade-up"
                data-aos-once="false"
                data-aos-offset="180"
                datax-aos-duration="70"
                data-aos-delay="300"
              >
                <b-icon
                  icon="check-circle"
                  width="23px"
                  height="23px"
                  class="coursier-white"
                ></b-icon>
                <div style="margin-left: 13px;">
                  Offre prépayée sans montant minimal
                </div>
              </div>
              <!-- <div class="d-flex align-items-center mt-4" data-aos="fade-up" data-aos-once="false" data-aos-offset="180"  datax-aos-duration="70" data-aos-delay="400">
                <b-icon icon="check-circle" width="23px" height="23px" class="coursier-white"></b-icon>
                <div style="margin-left: 13px;">Imprimante à étiquettes offerte</div>
              </div> -->
              <div
                class="coxl-12 d-flex mt-5 max-width"
                data-aos="fade-up"
                data-aos-once="false"
                data-aos-offset="0"
                datax-aos-duration="70"
                data-aos-delay="400"
              >
                <b-button
                  @click="showDialog"
                  size="sm"
                  class="max-width d-flex cxol-12 mt-4 justify-content-center align-items-center coursier-bg-secondary button-commande button-hover coursier-border-secondary border-radius-11"
                >
                  <div
                    class="d-flex bxorder-red flex-row align-items-center justify-content-center"
                  >
                    <div
                      class="text-nowrap text-size-12 bxorder-green text-weight-500"
                    >
                      Contactez un commercial
                    </div>
                  </div>
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  props: ['token'],
  methods: {
    showDialog () {
      const body = document.body
      body.style.height = '100vh'
      body.style.overflowY = 'hidden'
      location.hash = 'popup1'
    }
  }
}
</script>
<style scoped>
.text-contactez {
  font-size: 13px;
}
.linear-gradient {
  background: linear-gradient(black, #434c5c, #434c5c);
}
.border-black {
  border: thin solid black;
}
</style>
