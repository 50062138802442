<template>
  <b-col md="12">
    <b-row
      class="mt-3 align-itxems-end justify-content-between"
      styxle="padding: 0;"
    >
      <b-col md="5">
        <div
          class="text-title"
          data-aos="zoom-in-right"
          data-aos-dexlay="200"
          data-aos-duration="700"
        >
          Tournée Standard
        </div>
        <b-col
          style="margin-top: 20px;"
          data-aos="zoom-in-right"
          data-aos-dexlay="200"
          data-aos-duration="700"
        >
          <div class="text-marche mt-4">COMMENT ÇA MARCHE ?</div>
        </b-col>
        <b-col class="d-fxlex justifyx-content-center padxding-top-200">
          <div class="padding-top-50 mt-4">
            <div v-for="(items, index) in listsEtapes" :key="index">
              <div
                data-aos="fade-up"
                data-aos-anchor-placement="center-bottom"
                :data-aos-delay="200 * index"
                data-aos-duration="700"
                @click="changeValueCheck(index)"
              >
                <div
                  :class="!items.check ? 'collapsed' : null"
                  :aria-expanded="items.check ? 'true' : 'false'"
                  class="d-flex justify-content-between align-items-center cursor-pointer"
                >
                  <div
                    class="col-11 d-flex text-size-13"
                    :class="{
                      'coursier-primary': items.check,
                      'text-white': !items.check
                    }"
                  >
                    {{ items.title }}
                  </div>
                  <b-icon
                    :icon="
                      !items.check ? 'arrow-down-short' : 'arrow-right-short'
                    "
                    width="35px"
                    height="35px"
                    :class="!items.check ? 'flech' : 'coursier-primary'"
                  />
                </div>
                <b-collapse v-model="items.check">
                  <div class="padding-collapse">
                    <div class="text-subtitle-2 text-weight-250">
                      {{ items.description }}
                    </div>
                  </div>
                </b-collapse>
                <div class="d-flex col-12 d-flex flex-row align-items-center">
                  <div class="col-12">
                    <hr v-if="!items.check" />
                    <div
                      v-else
                      class="col-12 test margin-collapse"
                      style="margin-bottom: 20px;"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-col>
        <div class="d-flex flex-row" style="margin-top: 20px;">
          <div
            class="d-flex flex-row text-white coursier-border-primary p-1 card-btn-group border-radius-11"
            data-aos="zoom-in"
            data-aos-duration="700"
            data-aos-delay="600"
            data-aos-once="false"
          >
            <b-col
              class="d-flex flex-row align-items-center justify-content-center cursor-pointer border-radius-11"
              :class="
                checkDesktopOrPhone
                  ? 'coursier-border-primary coursier-bg-primary-btn'
                  : ''
              "
              @click="onCheckDesktopOrPhone(true, 'click')"
            >
              <b-img
                :src="require('../assets/images/icon-tele.png')"
                fluxid
                class="color-tv width-tv"
              />
            </b-col>
            <b-col
              class="d-flex flex-row align-items-center justify-content-center cursor-pointer border-radius-11"
              :class="
                !checkDesktopOrPhone
                  ? 'coursier-border-primary coursier-bg-primary-btn'
                  : ''
              "
              @click="onCheckDesktopOrPhone(false, 'click')"
            >
              <b-img
                :src="require('../assets/images/icon-phone.png')"
                fluxid
                class="color-tv width-phone"
              />
            </b-col>
          </div>
        </div>
      </b-col>

      <b-col
        xs="12"
        sm="12"
        md="6"
        class="d-flex flex-column justify-content-center dicrection-end-center borderx-green padding-top-image"
      >
        <div
          class="slideshow-container border-Xred col-12"
          style="height: 80%; min-height: 300px"
        >
          <div
            class="d-flex flex-row align-items-center justify-content-center"
            style="height: 100%"
          >
            <img
              class="width-image justify-content-center d-flex"
              :src="
                checkDesktopOrPhone
                  ? require('../assets/images/Calque.png')
                  : require('../assets/images/calque-phone.png')
              "
            />
            <div
              v-for="(image, index) in tabCheckImage"
              :key="index"
              class="mySlides position-absolute"
            >
              <img
                :src="image"
                class="width-image"
                :style="{
                  height: !checkDesktopOrPhone ? '645px' : '',
                  margin: !checkDesktopOrPhone ? '-30px 0px 0px 0px ' : ''
                }"
              />
            </div>
          </div>
          <div class="prev" @click="plusSlides(-1)">
            <span class="chevron left"></span>
          </div>
          <div class="next" @click="plusSlides(1)">
            <span class="chevron right"></span>
          </div>
        </div>
        <div style="text-align:center; padding: 20px 0px;">
          <span
            v-for="(image, index) in tabCheckImage"
            :key="index"
            :class="{ active: slideIndex == index + 1 }"
            class="dot"
            @click="currentSlide(index + 1)"
          ></span>
        </div>
      </b-col>
    </b-row>
    <b-col
      md="12"
      class="d-flex flex-row marging-top-50 justify-content-center"
    >
      <div
        class="py-2 justify-content-between maxrgin-right-creer"
        data-aos="zoom-in"
        data-aos-duration="700"
      >
        <b-button
          @click="toApp"
          size="sm"
          class="coursier-bg-primary-btn button-commande button-hover coursier-border-primary border-radius-11"
        >
          <div
            class="d-flex bxorder-red flex-row align-items-center justify-content-center"
          >
            <div class="text-nowrap text-size-12 bxorder-green text-weight-500">
              Lancez une livraison
            </div>
          </div>
        </b-button>
      </div>
    </b-col>
  </b-col>
</template>
<script>
export default {
  props: ['token'],
  data () {
    return {
      tk: '',
      stepCheck: 0,
      slideIndexTmp: 1,
      tabCheckImage: [],
      listsEtapes: [
        {
          title: 'Etape 01',
          description:
            'Sélectionnez votre point de départ et vos points de livraison.',
          check: false,
          listDesktopPicture: [
            require('../assets/images/standart/etape1/web/11.png'),
            require('../assets/images/standart/etape1/web/22.png'),
            require('../assets/images/standart/etape1/web/33.png'),
            require('../assets/images/standart/etape1/web/44.png')
          ],
          listPhonePicture: [
            require('../assets/images/standart/etape1/mobile/11.png'),
            require('../assets/images/standart/etape1/mobile/22.png'),
            require('../assets/images/standart/etape1/mobile/33.png'),
            require('../assets/images/standart/etape1/mobile/44.png')
          ]
        },
        {
          title: 'Etape 02',
          description:
            'Inscrivez-vous en saisissant vos informations personnelles et payez votre course.',
          check: false,
          listDesktopPicture: [
            require('../assets/images/standart/etape2/web/11.png'),
            require('../assets/images/standart/etape2/web/22.png'),
            require('../assets/images/standart/etape2/web/33.png')
          ],
          listPhonePicture: [
            require('../assets/images/standart/etape2/mobile/11.png'),
            require('../assets/images/standart/etape2/mobile/22.png'),
            require('../assets/images/standart/etape2/mobile/33.png')
          ]
        },
        {
          title: 'Etape 03',
          description: 'Imprimez et collez les étiquettes sur vos envois.',
          check: false,
          listDesktopPicture: [
            require('../assets/images/standart/etape3/web/11n.png'),
            require('../assets/images/standart/etape3/web/22n.png')
          ],
          listPhonePicture: [
            // require('../assets/images/standart/etape3/mobile/11.png'),
            // require('../assets/images/standart/etape3/mobile/22.png')
            require('../assets/images/standart/etape2/mobile/44.png'),
            require('../assets/images/standart/etape2/mobile/55.png')
          ]
        },
        {
          title: 'Etape 04',
          description: 'Mise en relation, enlèvement.',
          check: false,
          listDesktopPicture: [
            require('../assets/images/standart/etape3/web/11.png'),
            require('../assets/images/standart/etape3/web/22.png')
            // require('../assets/images/standart/etape4/web/11.png'),
            // require('../assets/images/standart/etape4/web/22.png'),
            // require('../assets/images/standart/etape4/web/33.png'),
            // require('../assets/images/standart/etape4/web/44.png')
          ],
          listPhonePicture: [
            require('../assets/images/standart/etape3/mobile/11.png'),
            require('../assets/images/standart/etape3/mobile/22.png')
          ]
        },
        {
          title: 'Etape 05',
          description:
            'Suivis de votre coursier en temps réel et preuves de livraisons.',
          check: false,
          listDesktopPicture: [
            require('../assets/images/standart/etape5/web/11.png'),
            require('../assets/images/standart/etape5/web/22.png'),
            require('../assets/images/standart/etape5/web/33.png'),
            require('../assets/images/standart/etape5/web/44.png')
          ],
          listPhonePicture: [
            require('../assets/images/standart/etape4/mobile/22.png'),
            require('../assets/images/standart/etape4/mobile/33.png'),
            require('../assets/images/standart/etape4/mobile/44.png'),
            require('../assets/images/standart/etape4/mobile/55.png')
            // require('../assets/images/standart/etape4/mobile/66.png')
          ]
        }
      ],
      checkDesktopOrPhone: true,
      slide: 0,
      slideIndex: 1,
      sliding: null,
      slides: [],
      dots: [],
      timeout: null
    }
  },
  mounted () {
    this.load()
  },
  created () {
    this.listsEtapes[0].check = true
    this.onCheckDesktopOrPhone(true)
  },
  beforeDestroy () {
    clearTimeout(this.timeout)
  },
  methods: {
    load () {
      this.slides = document.getElementsByClassName('mySlides')
      this.dots = document.getElementsByClassName('dot')
      this.listsEtapes[0].check = true
      this.onCheckDesktopOrPhone(true)
      this.slideIndex = 1
      this.slideIndexTmp = 1
      this.showSlides(this.slideIndex, true)
    },
    changeValueCheck (index) {
      for (var i = 0; i < this.listsEtapes.length; i++) {
        if (i === index) {
          this.listsEtapes[i].check = true
          this.stepCheck = i
          this.onCheckDesktopOrPhone(this.checkDesktopOrPhone)
          this.slideIndex = 1
          this.slideIndexTmp = 1
          this.showSlides(this.slideIndex, true)
        } else {
          this.listsEtapes[i].check = false
        }

        // console.log('stepCheck :::: ', this.stepCheck)
      }
    },
    onCheckDesktopOrPhone (value, act) {
      this.checkDesktopOrPhone = value
      clearTimeout(this.timeout)
      this.timeout = null
      for (var j = 0; j < this.listsEtapes.length; j++) {
        if (j === this.stepCheck) {
          if (this.checkDesktopOrPhone) {
            // console.log('desktop ::: ', this.checkDesktopOrPhone)
            this.tabCheckImage = this.listsEtapes[j].listDesktopPicture
          } else {
            // console.log('mobile ::: ', this.checkDesktopOrPhone)
            this.tabCheckImage = this.listsEtapes[j].listPhonePicture
          }
        }
      }
      if (act == 'click') {
        this.slideIndex = 0
        this.slideIndexTmp = 0
        this.plusSlides(1)
      }
    },
    onSlideStart () {
      this.sliding = true
    },
    onSlideEnd () {
      this.sliding = false
    },
    plusSlides (n) {
      this.slideIndex += n
      clearTimeout(this.timeout)
      this.showSlides(this.slideIndex)
    },
    currentSlide (n) {
      this.slideIndex = n
      clearTimeout(this.timeout)
      this.showSlides(this.slideIndex)
    },
    showSlides (n, mounted) {
      let i
      let slides = [...this.slides]
      let dots = [...this.dots]

      console.log(this.slideIndexTmp, this.slideIndex)

      if (n > slides.length) {
        this.slideIndex = 1
      }
      if (n < 1) {
        this.slideIndex = slides.length
      }

      for (i = 0; i < slides.length; i++) {
        console.log(
          '////',
          i,
          this.slideIndexTmp - 1,
          i == this.slideIndexTmp - 1
        )
        if (i == this.slideIndexTmp - 1 && !mounted) {
          let _i = this.slideIndexTmp - 1
          slides[i].style.display = 'block'
          slides[i].className = 'mySlides zoxom-in-left'
          setTimeout(() => {
            console.log(slides[_i], _i)
            slides[_i].style.display = 'none'
          }, 0)
        } else {
          slides[i].style.display = 'none'
        }
      }

      for (i = 0; i < dots.length; i++) {
        dots[i].className = dots[i].className.replace(' active', '')
      }

      setTimeout(() => {
        slides[this.slideIndex - 1].style.display = 'block'
        slides[this.slideIndex - 1].className = 'mySlides zoxom-in'
        dots[this.slideIndex - 1].className += ' active'
        this.slideIndexTmp = this.slideIndex
        // this.timeout = setTimeout(() => {
        //   this.slideIndex += 1
        //   this.showSlides(this.slideIndex)
        // }, 3000);
      }, 0)
    },
    toApp () {
      // window.location.href = '/app/'
      if (window.openApp) {
        window.openApp(this.tk)
      }
    }
  },
  watch: {
    token (tk) {
      this.tk = tk
    }
  }
}
</script>
<style>
.test {
  border: 0.5px solid #f77f00;
}
.width-image {
  width: 100%;
  height: 490px;
}
.width-image img {
  height: 100%;
  object-fit: contain;
}

.flech {
  color: grey;
}
.color-tv {
  color: white;
}
.marging-top-50 {
  margin-top: 50px;
}
.width-tv {
  height: 18px;
  object-fit: contain;
}
.width-phone {
  height: 22px;
  object-fit: contain;
}
.card-btn-group {
  height: 42px;
  border-radius: 11px;
  width: 130px;
}
.height-1 {
  height: 1px;
}

.slideshow-container:hover > .prev,
.slideshow-container:hover > .next {
  display: block;
}

@media (max-width: 575px) {
  .padding-top-image {
    padding-top: 20px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .padding-top-20 {
    padding-top: 20px;
  }
  .width-image {
    width: 467.51px;
  }
  .padding-top-image {
    padding-top: 40px;
  }
}
@media (min-width: 992px) and (max-width: 1200px) {
}
@media (min-width: 1201px) {
}
</style>
