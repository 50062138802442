<template>
  <div>
    <b-row
      class="col-12 mt-3 d-flex flex-row justify-content-end bxorder-green"
      style="padding: 0;"
    >
      <b-col
        md="6"
        class="left padding-left paddxing-md-70 justify-content-between"
      >
        <div
          data-aos="slide-right"
          data-aos-duration="1000"
          data-aos-once="true"
        >
          <div
            data-aos="slide-right"
            data-aos-duration="1000"
            data-aos-once="true"
            class="mt-5 mt-sm-0 mt-md-0 mt-lg-0 mt-xl-0 pt-2 bxorder-red"
          >
            <span
              class="text-degrade maxrgin-top-22 text-weight-bold"
              data-aos="slide-right"
              data-aos-duration="1000"
              data-aos-once="true"
            >
              Coursier ®
            </span>
            <div class="text-title mt-3">
              La livraison à Abidjan n'a jamais été aussi simple !
            </div>
          </div>
          <b-col
            md="11"
            lg="9"
            class="padding-md-70"
            data-aos="slide-right"
            data-aos-duration="1000"
            data-aos-once="true"
            data-aos-delay="300"
          >
            <div class="text-subtitle text-weight-250 boxrder-red">
              Bénéficiez d'un service de livraison premium entièrement
              digitalisé pour tous vos besoins avec la technologie du dernier
              kilomètre.
            </div>
          </b-col>
        </div>
        <div
          data-aos="zoom-in"
          data-aos-duration="1000"
          data-aos-once="true"
          data-aos-delay="1100"
          data-aos-offset="0"
          class="padding-md-70"
        >
          <b-row class="col-12 bxorder-blue">
            <b-col class="boxrder-green">
              <div class="py-2 justify-content-between maxrgin-right-creer">
                <b-button
                  size="sm"
                  @click="toApp"
                  class="coursier-bg-primary-btn button-commande button-hover coursier-border-primary border-radius-11"
                >
                  <div
                    class="d-flex bxorder-red flex-row align-items-center justify-content-center"
                  >
                    <div class="text-nowrap text-size-12 text-weight-500">
                      Lancez une livraison
                    </div>
                  </div>
                </b-button>
              </div>
            </b-col>
            <b-col class="d-flex">
              <div v-if="isWeb" class="pt-2 justify-content-between">
                <div
                  class=" button-hover border-radius-11 justify-content-between d-flex"
                >
                  <b-button
                    @click="downloadAppIos()"
                    variant="white"
                    class="coursier-border-primary button-creer border-radius-11 justify-content-center d-flex align-items-center"
                    style="width: 48%"
                  >
                    <div
                      class="d-flex flex-row align-items-center justify-content-center"
                    >
                      <div
                        class="text-white d-flex justify-content-center contain bxorder-red"
                        style="width: 120px; height: 28px;"
                      >
                        <div class="col-4 d-flex justify-content-center">
                          <b-img
                            fluid
                            :src="require('../assets/images/appStore.png')"
                            class="contain"
                          />
                        </div>
                        <div
                          class="col-7 bordxer-blue flex-direction-column align-items-center"
                        >
                          <div class="col-12 d-flex" style="font-size: 7px">
                            Télécharger sur
                          </div>
                          <div class="col-12 d-flex" style="font-size: 14px;">
                            App Store
                          </div>
                        </div>
                      </div>
                    </div>
                  </b-button>
                  <b-button
                    @click="downloadAppAndroid()"
                    variant="white"
                    class="coursier-border-primary button-creer border-radius-11 justify-content-center d-flex align-items-center"
                    style="width: 48%;"
                  >
                    <div
                      class="d-flex flex-row align-items-center justify-content-center"
                    >
                      <div
                        class="text-white d-flex justify-content-center contain"
                        style="width: 120px; height: 28px;"
                      >
                        <div class="col-4 d-flex justify-content-center">
                          <b-img
                            fluid
                            :src="require('../assets/images/googlePlay.png')"
                            class="contain"
                          />
                        </div>
                        <div
                          class="col-7 flex-direction-column align-items-center"
                        >
                          <div class="col-12 d-flex" style="font-size: 7px">
                            Télécharger sur
                          </div>
                          <div class="col-12 d-flex" style="font-size: 12.5px;">
                            Google play
                          </div>
                        </div>
                      </div>
                    </div>
                  </b-button>
                </div>
              </div>
              <div v-else class="py-2 boxrder-green">
                <b-button
                  @click="downloadApp()"
                  size="sm"
                  variant="white"
                  class="coursier-border-primary button-creer border-radius-11 boxrder-red justify-content-center d-flex align-items-center"
                >
                  <div
                    class="col-10 d-flex flex-row align-items-center justify-content-around"
                  >
                    <div
                      class="col-2 text-white d-flex justify-content-center"
                      style="width: 30px; height: 30px"
                    >
                      <b-img
                        v-if="isAndroid"
                        fluid
                        :src="require('../assets/images/play-store.png')"
                        class="coursier-primary contain"
                      />
                      <b-img
                        v-if="isIOS"
                        fluid
                        :src="require('../assets/images/Icon-A.png')"
                        class="coursier-primary contain"
                      />
                    </div>
                    <div
                      class="col-8 d-flex text-nowrap coursier-primary text-size-12 text-weight-500"
                    >
                      Télécharger l'App
                    </div>
                  </div>
                </b-button>
              </div>
            </b-col>
            <b-col class="bxorder-red"></b-col>
          </b-row>
        </div>
      </b-col>
      <b-col
        md="6"
        data-aos="slide-left"
        data-aos-duration="1000"
        class="wixdth-image border-rxed direction-end-center d-flex linear-gradient right"
      >
        <b-img
          :src="require('../assets/images/home2.png')"
          fluxid
          class="wixdth-image width-120 bordexr-red"
        ></b-img>
      </b-col>
    </b-row>
  </div>
</template>
<script>
export default {
  props: ['token'],
  data () {
    return {
      isAndroid: false,
      isWeb: false,
      isIOS: false,
      tk: ''
    }
  },
  mounted () {
    this.isAndroid =
      navigator.userAgent.toLowerCase().indexOf('android') > -1 ||
      /(android)/i.test(navigator.userAgent)
    this.isIOS =
      navigator.userAgent.toLowerCase().indexOf('iphone') > -1 ||
      /iPad|iPhone|iPod/.test(navigator.userAgent)
    this.isWeb = !this.isAndroid && !this.isIOS
  },
  methods: {
    toApp () {
      // window.location.href = '/app/'
      if (window.openApp) {
        window.openApp(this.tk)
      }
    },
    downloadApp () {
      if (this.isAndroid) {
        this.downloadAppAndroid()
      }
      if (this.isIOS) {
        this.downloadAppIos()
      }
    },
    downloadAppIos () {
      window.location.href = 'https://apps.apple.com/app/coursier/id6443798491'
    },
    downloadAppAndroid () {
      window.location.href =
        'http://play.google.com/store/apps/details?id=ci.coursier.prod'
    }
  },
  watch: {
    token (tk) {
      this.tk = tk
    }
  }
}
</script>
<style>
.direction-end-center {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.margin-top-xs-48 {
  margin-top: 48px;
}
.text-degrade {
  font-size: 85px;
  font-weight: 800;
  line-height: 60px;
  letter-spacing: 2px;
  width: 110%;
  background-image: -webkit-linear-gradient(top right, #fef5ec, #f39331);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.card-pub {
  width: 145px;
  height: 43px;
}

.width-imagex {
  object-fit: contain;
}
.width-image {
  width: 750px;
  object-fit: contain;
}
.width-image img {
  height: 120%;
  object-fit: contain;
}
.button-commande {
  width: 230px;
  height: 47px;
  border-radius: 4px;
}
.button-creer {
  width: 230px;
  height: 47px;
  border-radius: 4px;
}
.margin-left-icons {
  margin-left: 14px;
}
.margin-right-creer {
  margin-right: 8px;
}
.padding-top-telecharger {
  padding-top: 96px;
}
.padding-left-telecharger {
  padding-left: 59px;
}
.flex-reverse-block {
  display: flex;
  flex-direction: row;
}
.padding-top-image {
  padding-top: 0px;
}
.width-120 {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.padding-md-70 {
  padding-top: 40px;
}
@media (max-width: 575px) {
  .direction-end-center {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .flex-reverse-block {
    display: flex;
    flex-direction: column-reverse;
  }
  .padding-top-image {
    padding-top: 40px;
  }
  .padding-top-telecharger {
    padding-top: 40px;
  }
  .padding-left-telecharger {
    padding-left: 10px;
  }
  .card-pub {
    width: 120px;
  }
  .margin-top-xs-48 {
    margin-top: 30px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .padding-top-5 {
    padding-top: 20px;
  }
  .width-image {
    width: 467.51px;
  }
  .direction-end-center {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .flex-reverse-block {
    display: flex;
    flex-direction: column-reverse;
  }
  .padding-top-image {
    padding-top: 40px;
  }
  .padding-top-telecharger {
    padding-top: 40px;
  }
  .padding-left-telecharger {
    padding-left: 12px;
  }
  .margin-top-xs-48 {
    margin-top: 30px;
  }
}
@media (max-width: 768px) {
  .text-title2 {
    font-size: 25px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 2.5px;
    font-weight: bold;
    margin-bottom: -10px;
  }
  .text-degrade {
    font-size: 50px;
    font-weight: 800;
    line-height: 60px;
    letter-spacing: 2px;
    width: 110%;
    background-image: -webkit-linear-gradient(top right, #fef5ec, #f39331);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
@media (min-width: 768px) and (max-width: 990px) {
  .padding-left-telecharger {
    padding-left: 20px;
  }
  .text-title2 {
    font-size: 25px;
    font-weight: 500;
    line-height: 40px;
    letter-spacing: 2.5px;
    font-weight: bold;
  }
  .text-degrade {
    font-size: 50px;
    font-weight: 800;
    line-height: 60px;
    letter-spacing: 2px;
    width: 110%;
    background-image: -webkit-linear-gradient(top right, #fef5ec, #f39331);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
@media (min-width: 980px) and (max-width: 1290px) {
  .text-title2 {
    font-size: 28px;
    font-weight: 500;
    line-height: 50px;
    letter-spacing: 2.5px;
    font-weight: bold;
  }
  .text-degrade {
    font-size: 70px;
    font-weight: 800;
    line-height: 60px;
    letter-spacing: 2px;
    width: 110%;
    background-image: -webkit-linear-gradient(top right, #fef5ec, #f39331);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
@media (min-width: 1291px) and (max-width: 1350px) {
  .text-title2 {
    font-size: 35px;
    font-weight: 500;
    line-height: 55px;
    letter-spacing: 2.5px;
    font-weight: bold;
  }
}
@media (min-width: 1351px) and (max-width: 1360px) {
  .text-title2 {
    font-size: 40px;
    font-weight: 500;
    line-height: 55px;
    letter-spacing: 2.5px;
    font-weight: bold;
  }
}
@media (min-width: 1361px) {
  .text-title2 {
    font-size: 44px;
    font-weight: 500;
    line-height: 65px;
    letter-spacing: 2.5px;
    font-weight: bold;
    margin-top: -25px;
  }
}
@media (min-width: 1290px) {
  .width-120 {
    width: 110%;
    object-fit: contain;
  }
  .padding-md-70 {
    padding-top: 60px;
  }
}
</style>
